import BiotrackDetailsComponent from "@/components/inventory/traceability/batchReconciliation/biotrackDetails/biotrackDetails.component";
import { EventBus } from "@/event-bus";
import {
  Adjust,
  DataToConcile
} from "@/interfaces/biotrackInventoryReconciliation";
import { biotrackInventoryReconciliationService } from "@/services/biotrackInventoryReconciliation.service";
import { PageNavAction } from "@/types/types";
import { Component, Vue } from "vue-property-decorator";
import { Action } from "vuex-class";
import Template from "./biotrackBatchReconciliation.template.vue";
@Component({
  mixins: [Template],
  components: {
    BiotrackDetailsComponent
  }
})
export default class BiotrackBatchReconciliationComponent extends Vue {
  @Action("setPageNav", { namespace: "PageNavModule" })
  public setPageNav!: PageNavAction;
  public batchLoaded = false;
  public loading = false;
  public data!: DataToConcile;
  public adjustData!: Adjust;
  public listData!: { batchId: string };
  public productSKU!: string;
  public checkBTNEnabler: boolean = true;

  public cancel() {
    this.$router.push({
      name: "biotrack-inventory-reconciliation",
      params: {
        isDataReload: JSON.stringify(true),
        batchId: this.listData.batchId
      }
    });
  }
  // reconciliation for single batch
  public async reconcileTheBatch() {
    let payload;
    if (this.productSKU && this.adjustData) {
      payload = {
        batch_uid: this.data.data.batch_uid,
        concrete_sku: this.productSKU,
        local: {
          adjust: {
            quantities: [
              {
                inventory_location_id: this.data.data.biotrack_details!
                  .summary[0].inventory_location.id,
                quantity_value: Math.abs(
                  this.adjustData.quantities! -
                    this.reservedQuantity(this.data.data.biotrack_details)
                )
              }
            ],
            adjustment_reason: this.adjustData.adjustedReason,
            adjustment_type_id: this.adjustData.adjustedType
          }
        }
      };
    } else if (this.productSKU) {
      payload = {
        batch_uid: this.data.data.batch_uid,
        concrete_sku: this.productSKU
      };
    } else if (this.adjustData) {
      payload = {
        batch_uid: this.data.data.batch_uid,
        local: {
          adjust: {
            quantities: [
              {
                inventory_location_id: this.data.data.biotrack_details!
                  .summary[0].inventory_location.id,
                quantity_value: Math.abs(
                  this.adjustData.quantities! -
                    this.reservedQuantity(this.data.data.biotrack_details)
                )
              }
            ],
            adjustment_reason: this.adjustData.adjustedReason,
            adjustment_type_id: this.adjustData.adjustedType
          }
        }
      };
    }
    await biotrackInventoryReconciliationService.reconcileBatch(payload);
    this.$router.push({
      name: "biotrack-inventory-reconciliation",
      params: {
        isDataReload: JSON.stringify(true),
        batchId: this.listData.batchId
      }
    });
  }

  public reservedQuantity(param: any) {
    let quant = 0;
    const total =
      param &&
      param.summary &&
      param.summary.forEach((id: any) => {
        if (
          id.inventory_location.id === param.summary[0].inventory_location.id &&
          id.batch_fraction_status_type === "RESERVED"
        ) {
          quant = +id.quantity_value;
          return;
        }
      });
    return quant;
  }

  protected created() {
    EventBus.$on("model", (eventData: Adjust) => (this.adjustData = eventData));
    EventBus.$on("btn", (btnStatus: boolean) => {
      this.checkBTNEnabler = btnStatus;
    });
    this.loading = true;
    this.setPageNav({
      title: "batch reconciliation",
      isLoading: () => this.loading,
      rightActions: {
        generalActions: () => [
          {
            icon: "fal fa-check",
            action: this.reconcileTheBatch,
            vuetifyProps: () => ({
              disabled: this.checkBTNEnabler,
              loading: this.loading,
              fab: true,
              small: true
            })
          },
          {
            icon: "fal fa-times",
            action: this.cancel,
            vuetifyProps: () => ({
              loading: this.loading,
              fab: true,
              small: true
            })
          }
        ]
      }
    });
    this.loading = false;
    this.data = JSON.parse(this.$route.params.data);
    this.listData = JSON.parse(this.$route.params.listData);
  }
  protected async mounted() {
    this.loading = true;
    this.$nextTick(() => {
      this.batchLoaded = true;
      this.loading = false;
    });
    if (this.data === undefined) {
      this.$router.push({ name: "products-view" });
    }
    EventBus.$on("reqSKU", (sku: string) => {
      this.productSKU = sku;
    });
  }
}
