import { AdjustmentType } from "@/components/batch/adjust/models/batch-adjust.model";
import { EventBus } from "@/event-bus";
import {
  Adjust,
  BatchesData
} from "@/interfaces/biotrackInventoryReconciliation";
import { batchService } from "@/services/batch.service";
import { Component, Prop, Vue } from "vue-property-decorator";
import Template from "./biotrackAdjustModal.template.vue";
@Component({
  mixins: [Template]
})
export default class BiotrackAdjustModalComponent extends Vue {
  public adustmentArray: AdjustmentType[] = [];
  @Prop() public batchData!: BatchesData;

  public model: Adjust = {
    quantities: 0,
    adjustedReason: "",
    adjustedType: 0
  };

  public get currentQuantity() {
    return (
      +(this.batchData.data.biotrack_details.quantity.AVAILABLE || 0) +
      +(this.batchData.data.biotrack_details.quantity.RESERVED || 0)
    );
  }

  public get difference() {
    const diff = Math.abs(
      +this.batchData.data.traceability_details.quantity! - this.currentQuantity
    );
    const percent = ((diff / this.currentQuantity) * 100).toFixed(2);
    return `${diff} (${percent})`;
  }

  public cancel() {
    this.$emit("reject");
  }

  public get btnEnabler() {
    if (
      this.batchData &&
      this.batchData.data.biotrack_details.state_reporting_type.name ===
        this.batchData.data.traceability_details.state_reporting_type.name &&
      this.batchData.data.biotrack_details.strain.name ===
        this.batchData.data.traceability_details.strain &&
      (this.model
        ? this.model.quantities
        : (this.batchData.data.biotrack_details.quantity.AVAILABLE || 0) +
            (this.batchData.data.biotrack_details.quantity.RESERVED || 0) ===
          this.batchData.data.traceability_details.quantity)
    ) {
      return false;
    }
  }

  public async adjust() {
    const valid = await this.$validator.validateAll();
    if (valid) {
      EventBus.$emit("model", this.model); // sending data to biotrackDetails.component.ts
    }
    this.$emit("resolve", true);
    EventBus.$emit("btn", this.btnEnabler);
  }

  public async mounted() {
    this.adustmentArray = await batchService.getAdjustmentTypes();
  }
}
