import { EventBus } from "@/event-bus";
import {
  Adjust,
  BiotrackDetails,
  DataToConcile
} from "@/interfaces/biotrackInventoryReconciliation";
import { Product, productDefault } from "@/interfaces/product";
import { BatchReconciliationForm } from "@/interfaces/traceability";
import { productService } from "@/services/product.service";
import cloneDeep from "lodash/cloneDeep";
import debounce from "lodash/debounce";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import BiotrackAdjustModalComponent from "./adjustModal/biotrackAdjustModal.component";
import Template from "./biotrackDetails.template.vue";

@Component({
  mixins: [Template]
})
export default class BiotrackDetailsComponent extends Vue {
  @Prop() public batchData!: DataToConcile;
  public localSearch: string = "";
  public editableDisplay = {
    product: false
  };
  public defaultProductsList!: Product[];
  public productsList!: Product[];
  public productSearch = "";
  public productSelected: Product = cloneDeep(productDefault);
  public loading = {
    products: false,
    adjustData: false
  };
  public adjustData!: Adjust;

  protected dSearchProducts = debounce(context => {
    context.loadProducts(context.productSearch!);
  }, 500);
  public async openAdjust(isBiotrack: boolean) {
    this.$modals.load<BatchReconciliationForm.Adjust>(
      BiotrackAdjustModalComponent,
      {
        size: "fit",
        positionX: "center",
        positionY: "top"
      },
      {
        batchData: this.batchData
      }
    );
  }
  // updating adjusted quantity of biotrack details
  public get adjustDataQuantity() {
    this.loading.adjustData = true;
    setTimeout(() => {
      this.loading.adjustData = false;
    }, 10);
    return this.adjustData ? this.adjustData.quantities : null;
  }

  public toggleEdit() {
    this.editableDisplay.product = !this.editableDisplay.product;
  }

  public mounted() {
    this.setDefaultProductsList().then(() => {
      this.loadProducts();
    });
  }

  public created() {
    // receiving data from biotracAdjustModal.component
    EventBus.$on("model", (eventData: Adjust) => {
      this.loading.adjustData = true;
      (this.adjustData = eventData),
        (this.batchData.data.biotrack_details.quantity.AVAILABLE = this.adjustData.quantities);
      this.batchData.data.biotrack_details.quantity.RESERVED = 0;
      this.loading.adjustData = false;
    });
  }
  // updating Biotrack details data on selecting product
  public onChange(param: BiotrackDetails) {
    if (param.sku!) {
      this.batchData.data.biotrack_details.name = param.name;
      this.batchData.data.biotrack_details.batch_type.name =
        param.batch_type.name;
      this.batchData.data.biotrack_details.strain.name =
        param.strain.name || "";
      this.batchData.data.biotrack_details.state_reporting_type.name =
        this.batchData.data.traceability_details.state_reporting_type.name ||
        "";
      EventBus.$emit("reqSKU", param.sku!);
    }
    EventBus.$emit("btn", this.btnEnabler);
  }

  public get btnEnabler() {
    if (
      this.batchData &&
      this.batchData.data.biotrack_details.state_reporting_type.name ===
        this.batchData.data.traceability_details.state_reporting_type.name &&
      this.batchData.data.biotrack_details.strain.name ===
        this.batchData.data.traceability_details.strain &&
      (this.adjustData
        ? this.adjustData.quantities
        : (this.batchData.data.biotrack_details.quantity.AVAILABLE || 0) +
            (this.batchData.data.biotrack_details.quantity.RESERVED || 0) ===
          this.batchData.data.traceability_details.quantity)
    ) {
      return false;
    }
  }

  protected async setDefaultProductsList() {
    this.loading.products = true;
    this.defaultProductsList = await productService.get({
      "q[name_contains]": "",
      filter_state_reporting_type: true,
      state_reporting_type:
        this.batchData.state_reporting_type.traceability_details!
          .state_reporting_type!.name || ""
    });
    this.loading.products = false;
  }

  @Watch("productSearch")
  protected searchProducts() {
    if (!this.productSearch) {
      this.productSelected!.sku = "";
    }
    if (!this.productSelected!.sku) {
      this.dSearchProducts(this);
    }
  }

  protected async loadProducts(name: string = "") {
    this.loading.products = true;
    if (!name) {
      this.productsList = cloneDeep(this.defaultProductsList);
      this.loading.products = false;
      return;
    }
    this.productsList = await productService.get({
      "q[name_contains]": name,
      filter_state_reporting_type: true,
      state_reporting_type:
        this.batchData.state_reporting_type.traceability_details!
          .state_reporting_type!.name || ""
    });
    this.loading.products = false;
  }
}
