import { Batch } from "@/interfaces/batch";
import { BatchTransfer } from "@/interfaces/batchTransferManager";
import { Location } from "@/interfaces/location";
import { Product, productDefault } from "@/interfaces/product";
import { RetailSettings } from "@/interfaces/retailSettings";
import { Room } from "@/interfaces/room";
import NewBatchTransferService from "@/services/BatchTransferManager/NewBatchTransfer.service";
import { productService } from "@/services/product.service";
import { roomService } from "@/services/room.service";
import { PageNavAction } from "@/types/types";
import cloneDeep from "lodash/cloneDeep";
import debounce from "lodash/debounce";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import { newBatchTransferDetailsModelKeys } from "../../BatchTransfer/BatchTransferManager/ModelKeys";
import {
  NewBatchDefault,
  NewBatchItemDefault
} from "../../BatchTransfer/declarations";
import Template from "./CreateBatchReconciliation.template.vue";

@Component({
  mixins: [Template]
})
export default class CreateBatchReconciliation extends Vue {
  @Action("setPageNav", { namespace: "PageNavModule" })
  public setPageNav!: PageNavAction;
  @Getter("currentLocation", { namespace: "AuthModule" })
  public currentLocation!: Location;
  @Getter("currentRetailSettings", { namespace: "AuthModule" })
  public currentRetailSettings!: RetailSettings;
  @Prop() public batchData!: Batch;
  public roomList!: Room[];
  public defaultProductsList!: Product[];
  public productsList!: Product[];
  public loading = {
    rooms: false,
    products: false
  };
  public productSearch = "";
  public productSelected: Product = cloneDeep(productDefault);
  public batchType = "";
  public modelKeys = newBatchTransferDetailsModelKeys;
  public model!: BatchTransfer.NewBatch;
  public roomSelected!: Room;
  public listData = { batchId: "" };
  public unitOfMeasurement = "gms";
  public roomdisabler: boolean = false;
  protected dSearchProducts = debounce(context => {
    context.loadProducts(context.productSearch!);
  }, 500);

  public changeProduct(product: Product) {
    if (product) {
      this.batchType = product.batch_type!.name;
    } else {
      this.batchType = "";
    }
  }

  public cancel() {
    this.$router.push({
      name: "biotrack-inventory-reconciliation",
      params: {
        isDataReload: JSON.stringify(true),
        batchId: this.listData.batchId
      }
    });
  }

  public async save() {
    // @ts-ignore
    this.model.items = this.model.items.map(item => {
      return {
        ...item,
        product: this.productSelected,
        rooms: [
          { room_id: this.roomSelected.id, quantity: this.batchData.quantity }
        ],
        id: Date.now(),
        quantity: this.batchData.quantity,
        sku: this.productSelected.sku,
        tax_category_id: this.productSelected.tax_id || null,
        usable_weight_unit: this.batchData.usable_weight_unit || "g",
        usable_weight_value: +this.batchData.usable_weight_value || 0,
        weight_per_unit_unit: this.batchData.weight_per_unit_unit || "g",
        weight_per_unit_value: this.batchData.weight_per_unit_value || null,
        biotrack_traceability_id: this.batchData.traceability_id,
        state_reporting_type: this.batchData.state_reporting_type!.name
      };
    });
    this.model.sourceable_transfer_type = this.assignSourceTransferType();
    this.model.source_id = String(this.currentLocation.id);
    this.model.reconciliation = true;
    const resp = await NewBatchTransferService.create(this.model);
    if (resp) {
      this.$router.push({
        name: "biotrack-inventory-reconciliation",
        params: {
          isDataReload: JSON.stringify(true),
          batchId: this.listData.batchId
        }
      });
    }
  }

  public daysCounter(days: number) {
    const inventoryStartingDate: Date = this.currentRetailSettings.integrations!
      .biotrack_traceability.starting_inventory_date;
    const date = new Date(inventoryStartingDate);
    const differnceInMS = Date.now() - date.getTime();
    const remainingDays = days - Math.floor(differnceInMS / (1000 * 3600 * 24));
    return remainingDays;
  }

  public get remainingDaysForTrace() {
    const dayCount =
      this.currentLocation.state!.name === "Connecticut"
        ? this.daysCounter(30)
        : this.daysCounter(15);
    return dayCount;
  }

  public assignSourceTransferType() {
    if (this.remainingDaysForTrace >= 0) {
      if (
        this.currentLocation.state!.name === "Connecticut"
          ? this.remainingDaysForTrace <= 30
          : this.remainingDaysForTrace <= 15
      ) {
        return "INITIAL_INVENTORY_WITHOUT_VENDOR";
      }
    } else {
      return "NON_CANNABIS_INVENTORY_WITHOUT_VENDOR";
    }
  }

  public async mounted() {
    if (this.$route.params.data === undefined) {
      this.$router.push({ name: "products-view" });
    }
    this.setPageNav({
      title: "Inventory Reconciliation - Add Batch",
      rightActions: {
        generalActions: () => [
          {
            icon: "fal fa-check",
            action: this.save,
            vuetifyProps: () => ({
              disabled:
                !this.roomdisabler ||
                !(this.productSelected && this.productSelected.sku),
              fab: true,
              small: true
            })
          },
          {
            icon: "fal fa-times",
            action: this.cancel,
            fab: true,
            small: true
          }
        ]
      }
    });
    this.batchData = JSON.parse(
      this.$route.params.data
    ).data.traceability_details;
    this.listData = JSON.parse(this.$route.params.listData);
    this.model = cloneDeep(NewBatchDefault);
    this.model.items = cloneDeep([NewBatchItemDefault]);
    await this.loadRooms();
    this.setDefaultProductsList().then(() => {
      this.loadProducts();
    });
  }

  public changeRoom(room: Room) {
    this.roomdisabler = true;
    this.roomSelected = room;
  }

  protected async loadRooms() {
    const response = await roomService.getAll();
    if (response) {
      this.roomList = response;
    }
  }

  protected async setDefaultProductsList() {
    this.loading.products = true;
    this.defaultProductsList = await productService.get({
      "q[name_contains]": "",
      filter_state_reporting_type: true,
      state_reporting_type: this.batchData.state_reporting_type!.name || ""
    });
    this.loading.products = false;
  }

  @Watch("productSearch")
  protected searchProducts() {
    if (!this.productSearch) {
      this.productSelected!.sku = "";
    }
    if (!this.productSelected!.sku) {
      this.dSearchProducts(this);
    }
  }

  protected async loadProducts(name: string = "") {
    this.loading.products = true;
    if (!name) {
      this.productsList = cloneDeep(this.defaultProductsList);
      this.loading.products = false;
      return;
    }
    this.productsList = await productService.get({
      "q[name_contains]": name,
      filter_state_reporting_type: true,
      state_reporting_type: this.batchData.state_reporting_type!.name || ""
    });
    this.loading.products = false;
  }
}
